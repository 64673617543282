.input-edit-date{
    text-align: center;
    margin: 0 auto;
    width: auto !important;
    height: 80%;
  }
  
  .input-edit{
    text-align: center;
    width: 100%;
    margin: 0 auto;
    height: 80%;
  }
  .icon:hover{
    color: #488FB1;
    transition: .5s;
  }

  .icon{
    color:black;
    transition: .5s;
  }

  .green{
    color:green !important;
  }

  .red{
    color:red !important;
  }