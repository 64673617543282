@font-face {
    font-family: exo;
    src: url(../../sources/fonts/Exo-VariableFont_wght.ttf);
  }
::-webkit-scrollbar {display:none;}
.menu{
    background-color: #533E85 !important;
}
.display-4{
    font-size: 30px !important;
  }
.container {
    color: white !important;
    font-family: exo;
    font-size: 28px;
    font-weight: 400;
    padding: 2px 25px !important;
}
.container::after {
    width:0%;
    background: #488FB1;
    height: 3px;
    content: "";
    display: block;
    position: relative;
    align-self: center;
    margin:0 auto;
    transition: 1s;
}

.container:hover::after{
    transition: 1s;
    width:100%;
}

.container.active::after{
    transition: 1s;
    width:100%;
  }

li >input{
    display: none;
}

.logo{
    height: 52px;
    width: 52px;
    margin: 0 40px;
}

