@font-face {
    font-family: exo;
    src: url(/static/media/Exo-VariableFont_wght.373aeb3e.ttf);
  }
::-webkit-scrollbar {display:none;}
.menu{
    background-color: #533E85 !important;
}
.display-4{
    font-size: 30px !important;
  }
.container {
    color: white !important;
    font-family: exo;
    font-size: 28px;
    font-weight: 400;
    padding: 2px 25px !important;
}
.container::after {
    width:0%;
    background: #488FB1;
    height: 3px;
    content: "";
    display: block;
    position: relative;
    align-self: center;
    margin:0 auto;
    transition: 1s;
}

.container:hover::after{
    transition: 1s;
    width:100%;
}

.container.active::after{
    transition: 1s;
    width:100%;
  }

li >input{
    display: none;
}

.logo{
    height: 52px;
    width: 52px;
    margin: 0 40px;
}


.niu-table {
  background-color: #dfdfdf;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.input-filter{
  width: 80% !important;
  height: 20px !important;
  margin:0 auto;
  opacity: 100%; 
  transition: 2s;
  text-align: center;
}

.input-filter:-ms-input-placeholder{
  width: 0% !important;
  height: 20px !important;
  margin:0 auto;
  opacity: 0%;
  -ms-transition: 2s;
  transition: 2s;
}

.input-filter:placeholder-shown{
  width: 0% !important;
  height: 20px !important;
  margin:0 auto;
  opacity: 0%;
  transition: 2s;
}

.display-4{
  font-size: 30px !important;
}

th:hover .input-filter{
  height: 20px !important;
  width: 80% !important;
  opacity: 100%;
  transition: 2s;
}

.input-filter:focus{
  width: 80% !important;
  height: 20px !important;
  opacity: 100%;
  transition: 2s;
}



.input-edit-date{
    text-align: center;
    margin: 0 auto;
    width: auto !important;
    height: 80%;
  }
  
  .input-edit{
    text-align: center;
    width: 100%;
    margin: 0 auto;
    height: 80%;
  }
  .icon:hover{
    color: #488FB1;
    transition: .5s;
  }

  .icon{
    color:black;
    transition: .5s;
  }
.input-edit-date{
    text-align: center;
    margin: 0 auto;
    width: auto !important;
    height: 80%;
  }
  
  .input-edit{
    text-align: center;
    width: 100%;
    margin: 0 auto;
    height: 80%;
  }
  .icon:hover{
    color: #488FB1;
    transition: .5s;
  }

  .icon{
    color:black;
    transition: .5s;
  }

  .green{
    color:green !important;
  }

  .red{
    color:red !important;
  }

    
.input-edit-date{
    text-align: center;
    margin: 0 auto;
    width: auto !important;
    height: 80%;
  }
  
  .input-edit{
    text-align: center;
    width: 100%;
    margin: 0 auto;
    height: 80%;
  }
  .icon:hover{
    color: #488FB1;
    transition: .5s;
  }

  .icon{
    color:black;
    transition: .5s;
  }

  .green{
    color:green !important;
  }

  .red{
    color:red !important;
  }
